import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../redux/login';
import { useLocation } from 'react-router';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Icon from '../../../components/envago/Icon/Icon';
import LanguageButton from '../../common/LanguageButton/LanguageButton';
import { helpSelectors } from '../../../redux/help';
import { applicationSelectors } from '../../../redux/application';
import { jobsSelectors } from '../../../redux/jobs';
import CommunicationHeaderSection from '../../common/Communication/CommunicationHeaderSection';

interface Props {
    showIfNotLoggedIn?: boolean;
}

const NavigationBar = ({ showIfNotLoggedIn = false }: Props) => {
    const { t } = useTranslation();

    const location = useLocation();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
    const isEvaluationEnabled = useSelector(applicationSelectors.isEvaluationEnabled);
    const isGlobalHelpEnabled = useSelector(helpSelectors.isGlobalHelpEnabled);
    const reshareAllowed = useSelector(jobsSelectors.isAnyJobReshareAllowed);
    const hasSharableJobs = useSelector(jobsSelectors.hasShareableJobs);

    const navigation = showIfNotLoggedIn
        ? []
        : [
              {
                  name: t('menu.home'),
                  href: '/',
                  current: location.pathname === '/',
              },
          ];

    if (isLoggedIn) {
        navigation.push({
            name: t('menu.reading'),
            href: '/ablesung',
            current: location.pathname.indexOf('/ablesung') === 0,
        });
    }

    if (isLoggedIn && isEvaluationEnabled) {
        navigation.push({
            name: t('menu.evaluation'),
            href: '/auswertung',
            current: location.pathname.indexOf('/auswertung') === 0,
        });
    }

    if (isLoggedIn && reshareAllowed && hasSharableJobs) {
        navigation.push({
            name: t('menu.management'),
            href: '/management',
            current: location.pathname.indexOf('/management') === 0,
        });
    }

    if (isLoggedIn && isGlobalHelpEnabled) {
        navigation.push({
            name: t('menu.help'),
            href: '/help',
            current: location.pathname.indexOf('/help') === 0,
        });
    }

    if (!showIfNotLoggedIn) {
        if (!isLoggedIn) return null;
    }

    return (
        <Disclosure as="nav" className="bg-header md:shadow-none shadow-lg text-headertext sticky w-full z-30 h-16 top-0">
            {({ open, close }) => (
                <>
                    <div className="container mx-auto px-2 sm:px-2">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                {/* Mobile menu button*/}
                                {navigation.length > 0 && (
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-white bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <Icon className={'block h-6 w-6'} aria-hidden={'true'} path={Icon.Path.mdiClose} />
                                        ) : (
                                            <Icon path={Icon.Path.mdiMenu} className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                )}
                            </div>
                            <div className="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        style={{
                                            imageRendering: 'auto',
                                        }}
                                        className="block lg:hidden h-8 w-auto"
                                        src={'/api/public/application/logo.png'}
                                        alt="logo"
                                    />
                                    <img
                                        style={{
                                            imageRendering: 'auto',
                                        }}
                                        className="hidden lg:block h-8 w-auto"
                                        src={'/api/public/application/logo.png'}
                                        alt="logo"
                                    />
                                </div>
                                <div className="hidden md:flex md:ml-6 flex-rows flex-1 items-center">
                                    <div className="flex space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                key={item.name}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(push(item.href));
                                                }}
                                                href={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? 'bg-header_item_background_active text-header_item_text_active'
                                                        : 'text-header_item_text bg-header_item_background hover:bg-header_item_background_active-lighter',
                                                    'px-3 py-2 rounded-md text-sm font-medium cursor-pointer ',
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                    <div className={'text-white flex-1'} />
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                                {isLoggedIn && (
                                    <Menu as="div" className="ml-3 relative">
                                        {({ open }) => (
                                            <>
                                                <div className={'flex space-x-4'}>
                                                    <CommunicationHeaderSection />
                                                    <LanguageButton />
                                                    <Menu.Button className="bg-opacity-20 bg-gray-400 text-gray-400 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                                        <span className="sr-only">Open user menu</span>
                                                        <Icon className="h-6 w-6 m-1 rounded-full" aria-hidden="true" path={Icon.Path.mdiAccount} />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        static
                                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    >
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dispatch(push('/management'));
                                                                    }}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('menu.accounts')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dispatch(loginActions.logout());
                                                                    }}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('menu.logout')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                )}
                            </div>
                        </div>
                    </div>
                    <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Disclosure.Panel className="md:hidden  bg-white shadow-xl">
                            <div className="px-2 pt-2 pb-3 space-y-2">
                                {navigation.map((item) => (
                                    <Disclosure.Button key={item.href} as={Fragment}>
                                        <a
                                            className={classNames(
                                                item.current
                                                    ? 'bg-header_item_background_active text-header_item_text_active'
                                                    : 'bg-header_item_background_active-lighter text-header_item_text hover:bg-header_item_background_active-light',
                                                'block px-3 py-2 rounded-md text-base font-medium w-full text-center cursor-pointer',
                                            )}
                                            href={item.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                close();
                                                dispatch(push(item.href));
                                            }}
                                            key={item.name}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </a>
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
};

NavigationBar.SearchBar = () => {
    return (
        <div className={'navigation-bar--search'}>
            <input placeholder={'Suche'} />
        </div>
    );
};

NavigationBar.Title = () => {
    return (
        <div className={'navigation-bar--title items-center flex'}>
            <span>Hausverwaltung Dunkel</span>
        </div>
    );
};

NavigationBar.Icon = ({ className }: { className?: string }) => {
    return (
        <div className={classNames('navigation-bar--icon', className)}>
            <img alt="logo" className={'h-8 w-auto'} src={'https://envago.de/img/logo_bw.png'} />
        </div>
    );
};

export default NavigationBar;
