import React from 'react';

import './LoginScreen.less';
import { useDispatch, useSelector } from 'react-redux';
import { loginSelectors } from '../../redux/login';
import { applicationSelectors } from '../../redux/application';
import LabeledList from '../../components/envago/LabeledList/LabeledList';

import { Trans, useTranslation } from 'react-i18next';
import LanguageButton from '../common/LanguageButton/LanguageButton';
import ContactInformation from '../../components/envago/ContactInformation/ContactInformation';
import Login from './components/Login/Login';
import OptionalLabel from '../../components/envago/OptionalLabel/OptionalLabel';
import Button from '../../components/envago/Button/Button';
import { push } from 'connected-react-router';

const LoginScreen = ({ children }: { children: any }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const applicationName = useSelector(applicationSelectors.getApplicationName);

    const imprintConfig = useSelector(applicationSelectors.getImprintConfig);
    const privacyConfig = useSelector(applicationSelectors.getPrivacyConfig);

    const isLoggedIn = useSelector(loginSelectors.isLoggedIn);

    const isUnknownUserReadingAllowed = useSelector(applicationSelectors.isUnknownUserReadingAllowed);

    if (isLoggedIn) return children;

    return (
        <div className="login-screen container mx-auto max-w-screen-lg p-2">
            <div className={'p-4 bg-white shadow-xl rounded-xl'}>
                <div className={'bg-header -m-4 -mb-4 rounded-t-xl flex flex-col justify-center items-center gap-4 '}>
                    <div className={'p-2 m-2 rounded-md inline-block align-center h-16 md:h-24'}>
                        <img className="object-contain max-h-full h-full w-full" src={'/api/public/application/logo.png'} alt="App Logo" />
                    </div>
                </div>
                <hr className={'my-4'} />
                <div className={'flex'}>
                    <h1>{t('login.title', { applicationName })}</h1>
                    <div className={'flex-1'} />
                    <LanguageButton />
                </div>

                <Login />

                <hr className={'my-4'} />

                {isUnknownUserReadingAllowed && (
                    <>
                        <LabeledList className={'mt-4'}>
                            <OptionalLabel label={'Ablesung ohne Zugang'}>
                                <div>
                                    <p className={'mb-2'}>{t('unknownUserReading.loginScreen.text')}</p>
                                    <Button
                                        ghost={true}
                                        block={true}
                                        onClick={() => {
                                            dispatch(push('/ablesung-ohne-zugang'));
                                        }}
                                    >
                                        {t('unknownUserReading.loginScreen.buttonText')}
                                    </Button>
                                </div>
                            </OptionalLabel>
                        </LabeledList>
                        <hr className={'my-4'} />
                    </>
                )}

                <ContactInformation wrapper={LabeledList} className={'col-start-2 mb-8'} path={'login'} />

                <div className={'text-sm flex justify-center'}>
                    <p className={'max-w-2xl text-center'}>
                        <Trans i18nKey={'login.usage.text'} values={{ applicationName }}>
                            Diese Seite wird von {applicationName} bereitgestellt. Mit der Anmeldung und Nutzung erklärst du dich mit den
                            <a target={'_blank'} href={privacyConfig.url}>
                                Datenschutzbedingungen
                            </a>{' '}
                            einverstanden.
                        </Trans>
                        <br />
                        <a href={imprintConfig.url} target={'_blank'}>
                            {t('common.imprint')}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
